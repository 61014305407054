import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import blueStar_logo from "../images/blueStar_logo.png";
import { userAuth } from "../redux/index";
import swal from "sweetalert";

const Login = (props) => {
  const apiUrl = useSelector((state) => state.login.apiUrl);
  const dispatch = useDispatch();
  const [loginInfo, setLoginInfo] = useState({
    email: "",
    password: "",
  });
  const handelLogin = (e) => {
    const { value, name } = e.target;
    setLoginInfo({
      ...loginInfo,
      [name]: value,
    });
  };

  const onLogin = async (e) => {
    e.preventDefault();
    // setLoginInfo({
    //   email: "",
    //   password: "",
    // });
    if (
      loginInfo.email === "example@gmail.com" &&
      loginInfo.password === "123456789"
    ) {
      dispatch(
        userAuth({
          auth: true,
          company: "Daikin",
          apiUrl: "?company=Daikin",
        })
      );
    } else {
      swal("Oops", "Please enter currect username & password", "error");
    }
  };

  return (
    <div className="login-form-bx login_section">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 col-md-7 box-skew d-flex">
            <div className="authincation-content">
              <div className="mb-4">
                <h3 className="mb-1 font-w600">E-commerce</h3>
                <p className="">Sign in by entering information below</p>
              </div>
              {/* {props.errorMessage && (
                <div className="bg-red-300 text-red-900 border border-red-900 p-1 my-2">
                  {props.errorMessage}
                </div>
              )}
              {props.successMessage && (
                <div className="bg-green-300 text-green-900 border border-green-900 p-1 my-2">
                  {props.successMessage}
                </div>
              )} */}
              <form onSubmit={onLogin}>
                <div className="form-group">
                  <label className="mb-2 ">
                    <strong className="">Email</strong>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    value={loginInfo.email}
                    onChange={handelLogin}
                    name="email"
                    placeholder="Enter your email"
                  />
                  {/* {errors.email && (
                    <div className="text-danger fs-12">{errors.email}</div>
                  )} */}
                </div>
                <div className="form-group">
                  <label className="mb-2 ">
                    <strong className="">Password</strong>
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    value={loginInfo.password}
                    onChange={handelLogin}
                    name="password"
                    placeholder="Enter your password"
                  />
                  {/* {errors.password && (
                    <div className="text-danger fs-12">{errors.password}</div>
                  )} */}
                </div>
                <div className="form-row d-flex justify-content-between mt-4 mb-2">
                  <div className="form-group">
                    <div className="custom-control custom-checkbox ml-1 ">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="basic_checkbox_1"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="basic_checkbox_1"
                      >
                        Remember my preference
                      </label>
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <button type="submit" className="btn btn-primary btn-block">
                    Sign In
                  </button>
                </div>
              </form>
              <div className="new-account mt-2">
                <p className="mb-0">
                  Don't have an account?{" "}
                  <Link className="text-black">Sign up</Link>
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-5 d-flex box-skew1 login_section">
            <div className="inner-content align-self-center">
              <Link to="" className="login-logo">
                <img src={blueStar_logo} alt="" className="logo-text ml-1" />
              </Link>
              <h2 className="m-b10 text-white">Login To You Now</h2>
              <p className="m-b40 text-white">
                User Experience & Interface Design Strategy SaaS Solutions
              </p>
              <ul className="social-icons mt-4">
                <li>
                  <Link to={"#"}>
                    <i className="fa fa-facebook"></i>
                  </Link>
                </li>
                <li>
                  <Link to={"#"}>
                    <i className="fa fa-twitter"></i>
                  </Link>
                </li>
                <li>
                  <Link to={"#"}>
                    <i className="fa fa-linkedin"></i>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
