import React, { Fragment, useEffect, useState } from "react";
import "../css/form.css";
import swal from "sweetalert";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { categoryAddApi, productAddApi } from "../lib/api";
const Form = () => {
  const companyType = useSelector((state) => state.login.company);
  const apiUrl = useSelector((state) => state.login.apiUrl);
  const [categoryName, setCategoryName] = useState("");
  const [allategory, setAllCategory] = useState([]);
  const [productImage, setProductImage] = useState("");
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [selectedCategoryName, setSelectedCategoryName] = useState("");

  const handleImage = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProductImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleCategorySelection = (event) => {
    const selectedCategoryName = event.target.value;
    setSelectedCategoryName(selectedCategoryName);
    const selectedCategory = allategory.find(
      (cat) => cat.category_name === selectedCategoryName
    ); // Find the category object based on the name
    if (selectedCategory) {
      setSelectedCategoryId(selectedCategory.id); // Set the selected category ID
    } else {
      setSelectedCategoryId(null); // Reset the selected category ID if no category is found
    }
  };

  const handleCtegory = async (e) => {
    e.preventDefault();
    if (categoryName !== "") {
      try {
        const response = await categoryAddApi({
          category_name: categoryName,
        });
        console.log("Response from server:", response);
        if (response.statusCode === 1) {
          swal(
            "Category Create Successfully",
            "You clicked the button!",
            "success"
          );
          fatchCategory();
        } else {
          swal("Oops", "Something went wrong!", "error");
        }

        // Handle the response as needed
      } catch (error) {
        // Handle error if needed
        swal("Oops", "Something went wrong!", "error");
      }
    } else {
      swal("Oops", "Please enter the category name", "error");
    }
  };

  const fatchCategory = () => {
    axios
      .get("https://tworingz.com/ecommerce/api/product/fetchCategory.php")
      .then((res) => {
        console.log("res: ", res.data);
        if (res.data.statusCode === 0) {
          console.log(res.data);
        } else {
          setAllCategory(res.data);
        }
      })
      .catch((err) => {
        console.log("err: ", err);
      });
  };
  useEffect(() => {
    fatchCategory();
  }, []);

  const [productData, setProductDAta] = useState({
    selectitemcategory: "",
    itemname: "",
    mrp: "",
    partyprice: "",
    discount: "",
    itemmodel: "",
    minimumquantity: "",
    stocks: "",
    itemimage: "",
    description: "",
  });

  const handleChangeData = (val) => {
    const { name, value } = val.target;
    setProductDAta({
      ...productData,
      [name]: value,
    });
  };
  const handleProductAdd = async (e) => {
    console.log("productData.selectitemcategory: ", selectedCategoryId);
    console.log({
      cat_id: selectedCategoryId,
      cat_name: selectedCategoryName,
      name: productData.itemname,
      model: productData.itemmodel,
      description: productData.description,
      mrp: productData.mrp,
      party_price: productData.partyprice,
      minimum_qty: productData.minimumquantity,
      stocks: productData.stocks,
      image: productImage,
      active: "true",
      hot_product: "false",
    });
    e.preventDefault();
    if (selectedCategoryName !== "") {
      if (productImage !== "") {
        try {
          const response = await productAddApi({
            cat_id: selectedCategoryId,
            cat_name: selectedCategoryName,
            name: productData.itemname,
            model: productData.itemmodel,
            description: productData.description,
            mrp: productData.mrp,
            party_price: productData.partyprice,
            minimum_qty: productData.minimumquantity,
            stocks: productData.stocks,
            image: productImage,
            active: "true",
            hot_product: "false",
          });
          console.log("response: ", response);
          if (response.statusCode === 1) {
            swal(
              "Product Create Successfully",
              "You clicked the button!",
              "success"
            );
            setProductDAta({
              selectitemcategory: "",
              itemname: "",
              mrp: "",
              partyprice: "",
              discount: "",
              itemmodel: "",
              minimumquantity: "",
              stocks: "",
              itemimage: "",
              description: "",
            });
          } else {
            swal("Oops", "Something went wrong!", "error");
          }

          // Handle the response as needed
        } catch (error) {
          // Handle error if needed
          swal("Oops", "Something went wrong!", "error");
        }
      } else {
        swal("Oops", "Please add product image", "error");
      }
    } else {
      swal("Oops", "Please select a category", "error");
    }
  };
  return (
    <Fragment>
      <div className="form_container">
        <div className="row form_row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Create New Products</h4>
              </div>
              <div className="card-header">
                <form
                  className="d-flex gap-2"
                  action="#"
                  method="post"
                  style={{ width: "50%" }}
                >
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="form-group row">
                        <label
                          className="col-lg-5 col-form-label"
                          htmlFor="val-currency"
                        >
                          Create Category
                          <span className="text-danger"></span>
                        </label>
                        <div className="col-lg-7">
                          <input
                            autoComplete="off"
                            type="text"
                            className="form-control"
                            id="val-currency"
                            name="callnumber"
                            placeholder="Enter categoy name"
                            value={categoryName}
                            onChange={(e) => setCategoryName(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-8">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={handleCtegory}
                      >
                        save
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="card-body">
                <div className="form-validation">
                  <form
                    className="form-valide"
                    action="#"
                    method="post"
                    onSubmit={handleProductAdd}
                  >
                    <div className="row">
                      <div className="col-xl-6">
                        <div className="form-group row">
                          <label
                            className="col-lg-4 col-form-label"
                            htmlFor="val-currency"
                          >
                            Select Item Category
                            <span className="text-danger"></span>
                          </label>
                          <div className="col-lg-6">
                            <select
                              defaultValue={"option"}
                              className="form-control form-control-lg"
                              onChange={handleCategorySelection}
                              name="selectitemcategory"
                            >
                              <option>Please select a category</option>
                              {allategory?.map((ele) => {
                                return (
                                  <option key={ele?.id}>
                                    {ele.category_name}
                                  </option>
                                );
                              })}

                              {/* <option>Option 2</option>
                              <option>Option 3</option> */}
                            </select>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            className="col-lg-4 col-form-label"
                            htmlFor="val-username"
                          >
                            Item Name
                            <span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-6">
                            <input
                              autoComplete="off"
                              type="text"
                              className="form-control"
                              id="val-username"
                              name="itemname"
                              value={productData.itemname}
                              placeholder="Enter Item Name"
                              onChange={handleChangeData}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            className="col-lg-4 col-form-label"
                            htmlFor="val-email"
                          >
                            MRP <span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-6">
                            <input
                              autoComplete="off"
                              type="text"
                              className="form-control"
                              id="val-email"
                              name="mrp"
                              value={productData.mrp}
                              placeholder="Enter MRP"
                              onChange={handleChangeData}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            className="col-lg-4 col-form-label"
                            htmlFor="val-email"
                          >
                            Party Price <span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-6">
                            <input
                              autoComplete="off"
                              type="text"
                              className="form-control"
                              id="val-email"
                              name="partyprice"
                              value={productData.partyprice}
                              placeholder="Enter Party Price"
                              onChange={handleChangeData}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            className="col-lg-4 col-form-label"
                            htmlFor="val-password"
                          >
                            Discount (%)
                            <span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-6">
                            <input
                              autoComplete="off"
                              type="number"
                              className="form-control"
                              name="discount"
                              value={productData.discount}
                              placeholder="Enter Discount (%)"
                              onChange={handleChangeData}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            className="col-lg-4 col-form-label"
                            htmlFor="val-confirm-password"
                          >
                            Item Model
                            <span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-6">
                            <input
                              autoComplete="off"
                              type="text"
                              className="form-control"
                              name="itemmodel"
                              value={productData.itemmodel}
                              placeholder="Enter Item Model"
                              onChange={handleChangeData}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-xl-6">
                        <div className="form-group row">
                          <label
                            className="col-lg-4 col-form-label"
                            htmlFor="val-confirm-password"
                          >
                            Minimum Quantity
                            <span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-6">
                            <input
                              autoComplete="off"
                              type="number"
                              className="form-control"
                              name="minimumquantity"
                              value={productData.minimumquantity}
                              placeholder="Enter Minimum Quantity"
                              onChange={handleChangeData}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            className="col-lg-4 col-form-label"
                            htmlFor="val-confirm-password"
                          >
                            Stocks<span className="text-danger"></span>
                          </label>
                          <div className="col-lg-6">
                            <input
                              autoComplete="off"
                              type="number"
                              className="form-control"
                              name="stocks"
                              placeholder="Enter Stocks"
                              value={productData.stocks}
                              onChange={handleChangeData}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            className="col-lg-4 col-form-label"
                            htmlFor="val-confirm-password"
                          >
                            Item Image
                            <span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-6">
                            <input
                              style={{ height: "36px" }}
                              autoComplete="off"
                              type="file"
                              className="form-control"
                              name="itemimage"
                              placeholder="Enter Model Number"
                              onChange={handleImage}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            className="col-lg-4 col-form-label"
                            htmlFor="val-confirm-password"
                          >
                            Description<span className="text-danger"></span>
                          </label>
                          <div className="col-lg-6">
                            <textarea
                              style={{
                                height: "200px",
                              }}
                              autoComplete="off"
                              type="number"
                              className="form-control"
                              name="description"
                              placeholder="Enter Description"
                              value={productData.description}
                              onChange={handleChangeData}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-lg-8 ml-auto">
                            <button type="submit" className="btn btn-primary">
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Form;
