export const BASE_URL = "https://tworingz.com/ecommerce/api";

export const categoryAddApi = async (dataToSend) => {
  try {
    const response = await fetch(`${BASE_URL}/product/addCategory.php`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataToSend),
    });

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};

export const productAddApi = async (dataToSend) => {
  try {
    const response = await fetch(`${BASE_URL}/product/addProduct.php`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataToSend),
    });

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};

export const productDeleteApi = async (dataToSend) => {
  try {
    const response = await fetch(`${BASE_URL}/product/deleteProduct.php`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataToSend),
    });

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};
export const productUpdateApi = async (dataToSend) => {
  try {
    const response = await fetch(`${BASE_URL}/product/updateProduct.php`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataToSend),
    });

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};
