/// Menu
import MetisMenu from "metismenujs";
import React, { Component } from "react";
import { BrowserRouter as Router, NavLink } from "react-router-dom";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import "../../css/footer.css";
import "../../css/sidebar.css";
class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }
  componentWillUnmount() {}
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

class SideBar extends Component {
  /// Open menu
  componentDidMount() {
    // sidebar open/close
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);
  }
  state = {
    loveEmoji: false,
  };
  render() {
    /// Path
    let path = window.location.pathname;
    path = path.split("/");
    path = path[path.length - 1];

    return (
      <div
        className="deznav"
        style={{
          backgroundColor: "#40189D",
        }}
      >
        <PerfectScrollbar className="deznav-scroll">
          <MM className="metismenu" id="menu">
            <li className="">
              <NavLink
                className="has-arrow ai-icon"
                to="/"
                style={({ isActive }) => ({
                  color: isActive ? "#fff" : "#545e6f",
                  background: isActive ? "#0d6efd" : "#40189D",
                  borderTopRightRadius: "20px",
                  borderBottomRightRadius: "20px",
                })}
              >
                <i className="flaticon-381-networking"></i>
                <span className="nav-text">DashBoard</span>
              </NavLink>
            </li>
            <li className="">
              <NavLink
                className="has-arrow ai-icon"
                to="/form"
                style={({ isActive }) => ({
                  color: isActive ? "#fff" : "#545e6f",
                  background: isActive ? "#0d6efd" : "#40189D",
                  borderTopRightRadius: "20px",
                  borderBottomRightRadius: "20px",
                })}
              >
                <i className="flaticon-381-controls-3"></i>
                <span className="nav-text">Create Product</span>
              </NavLink>
            </li>
            <li className="">
              <NavLink
                className="has-arrow ai-icon"
                to="/orderinfo"
                style={({ isActive }) => ({
                  color: isActive ? "#fff" : "#545e6f",
                  background: isActive ? "#0d6efd" : "#40189D",
                  borderTopRightRadius: "20px",
                  borderBottomRightRadius: "20px",
                })}
              >
                <i className="flaticon-381-television"></i>
                <span className="nav-text">All Products</span>
              </NavLink>
            </li>
            <li className="">
              <NavLink
                className="has-arrow ai-icon"
                to="/download"
                style={({ isActive }) => ({
                  color: isActive ? "#fff" : "#545e6f",
                  background: isActive ? "#0d6efd" : "#40189D",
                  borderTopRightRadius: "20px",
                  borderBottomRightRadius: "20px",
                })}
              >
                <i className="flaticon-381-download"></i>
                <span className="nav-text">Orders</span>
              </NavLink>
            </li>
          </MM>
          <div className="copyright custom_copyright">
            <p>
              <strong>Ecommerce</strong> <br />© 2022 All Rights
              Reserved
            </p>
            <p>
              Developed
              <span
                className={`${
                  this.state.loveEmoji ? "heart heart-blast" : "heart"
                }`}
                onClick={() =>
                  this.setState({ loveEmoji: !this.state.loveEmoji })
                }
              ></span>{" "}
              by Ashar Infotech
            </p>
          </div>
        </PerfectScrollbar>
      </div>
    );
  }
}

export default SideBar;
