import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Modal, Nav } from "react-bootstrap";
import swal from "sweetalert";
import axios from "axios";
import Editable from "./Editable";
import "../css/orderinfo.css";
import ReactLoading from "react-loading";
import { RangeDatePicker } from "@y0c/react-datepicker";
import "@y0c/react-datepicker/assets/styles/calendar.scss";
import { Button } from "react-bootstrap";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { productUpdateApi, productDeleteApi } from "../lib/api";
// ==============get user Info=============

const DownloadReport = () => {
  const apiUrl = useSelector((state) => state.login.apiUrl);
  const [contents, setContents] = useState([]);
  const [PageLoading, setPageLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [filterProductData, setfilterProductData] = useState([]);
  const [validationError, setValidationError] = useState({
    pinCodeError: false,
    phonenumber1Error: false,
    phonenumber2Error: false,
  });
  const [downloadDateRange, setDownloadDateRange] = useState("");

  const [categoryName, setCategoryName] = useState("");
  const [allategory, setAllCategory] = useState([]);
  const [productImage, setProductImage] = useState("");
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [selectedCategoryName, setSelectedCategoryName] = useState("");

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  // console.log(screenWidth);
  // ================Update Product===========
  const [addCard, setAddCard] = useState(false);
  const [productId, setProdectId] = useState("");
  const [productData, setProductDAta] = useState({
    selectitemcategory: "",
    itemname: "",
    mrp: "",
    partyprice: "",
    discount: "",
    itemmodel: "",
    minimumquantity: "",
    stocks: "",
    itemimage: "",
    description: "",
  });
  const handleImage = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProductImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleCategorySelection = (event) => {
    const selectedCategoryName = event.target.value;
    setSelectedCategoryName(selectedCategoryName);
    const selectedCategory = allategory.find(
      (cat) => cat.category_name === selectedCategoryName
    ); // Find the category object based on the name
    if (selectedCategory) {
      setSelectedCategoryId(selectedCategory.id); // Set the selected category ID
    } else {
      setSelectedCategoryId(null); // Reset the selected category ID if no category is found
    }
  };

  const handleProductUpdate = (event, content) => {
    console.log("content: ", content);
    setProdectId(content.id);
    event.preventDefault();
    setProductDAta({
      selectitemcategory: content.category_name,
      itemname: content.name,
      mrp: content.mrp,
      partyprice: content.party_price,
      discount: "",
      itemmodel: content.model,
      minimumquantity: content.minimum_qty,
      stocks: content.stocks,
      itemimage: content.image_link,
      description: content.description,
    });
    setAddCard(true);
    // setUserData({
    //   ...content,
    //   tcrdate: customDate,
    //   appointmentdate: customAppDate,
    // });
  };

  const handleChangeData = (val) => {
    const { name, value } = val.target;
    setProductDAta({
      ...productData,
      [name]: value,
    });
  };

  const handleProductUpdateApi = async (e) => {
    console.log("productImage: ", productData.itemimage);
    e.preventDefault();
    if (productData.selectitemcategory !== "") {
      if (productData.itemimage !== "") {
        try {
          const response = await productUpdateApi({
            id: productId,
            cat_id: selectedCategoryId,
            cat_name: productData.selectitemcategory,
            name: productData.itemname,
            model: productData.itemmodel,
            description: productData.description,
            mrp: productData.mrp,
            party_price: productData.partyprice,
            minimum_qty: productData.minimumquantity,
            stocks: productData.stocks,
            image: productData.itemimage,
            active: "true",
            hot_product: "false",
          });
          console.log("response: ", response);
          if (response.statusCode === 1) {
            swal(
              "Product Update Successfully",
              "You clicked the button!",
              "success"
            );
            getProductData();
            setAddCard(false);
          } else {
            swal("Oops", "Something went wrong!", "error");
          }

          // Handle the response as needed
        } catch (error) {
          // Handle error if needed
          swal("Oops", "Something went wrong!", "error");
        }
      } else {
        swal("Oops", "Please add product image", "error");
      }
    } else {
      swal("Oops", "Please select a category", "error");
    }
  };

  const fatchCategory = () => {
    axios
      .get("https://tworingz.com/ecommerce/api/product/fetchCategory.php")
      .then((res) => {
        console.log("res: ", res.data);
        if (res.data.statusCode === 0) {
          console.log(res.data);
        } else {
          setAllCategory(res.data);
        }
      })
      .catch((err) => {
        console.log("err: ", err);
      });
  };
  useEffect(() => {
    fatchCategory();
  }, []);

  // ================Get Product===========

  const getProductData = async () => {
    setPageLoading(true);
    await axios({
      method: "get",
      url: `https://tworingz.com/ecommerce/api/product/fetchAdminOrder.php`,
    })
      .then((res) => {
        if (res.data.statusCode === 0) {
          setContents([]);
          setfilterProductData([]);
          setPageLoading(false);
        } else {
          const userDataTable = res.data;
          setContents(userDataTable);
          setfilterProductData(userDataTable);
          setPageLoading(false);
        }
      })
      .catch((err) => {
        console.log("api error", err);
      });
  };

  useEffect(() => {
    getProductData();
  }, []);
  // ====================Delete Product========

  const handleProductDelete = async (productId) => {
    console.log({
      id: productId,
    });
    setPageLoading(true);
    try {
      const response = await productDeleteApi({
        id: productId,
      });
      console.log("Response from server:", response);
      if (response.statusCode === 1) {
        swal(
          "Product Delete Successfully",
          "You clicked the button!",
          "success"
        );
        getProductData();
      } else {
        swal("Oops", "Something went wrong!", "error");
      }

      // Handle the response as needed
    } catch (error) {
      // Handle error if needed
      swal("Oops", "Something went wrong!", "error");
    }
  };

  // =========fetch all order ========

  //Edit start

  const [editContentId, setEditContentId] = useState(null);

  const [editFormData, setEditFormData] = useState({
    name: "",
    department: "",
    gender: "",
    education: "",
    mobile: "",
    email: "",
  });

  //update data function
  const handleEditFormChange = (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;
    const newFormData = { ...editFormData };
    newFormData[fieldName] = fieldValue;
    setEditFormData(newFormData);
  };

  // edit form data submit
  const handleEditFormSubmit = (event) => {
    event.preventDefault();
    const editedContent = {
      id: editContentId,
      name: editFormData.name,
      department: editFormData.department,
      gender: editFormData.gender,
      education: editFormData.education,
      mobile: editFormData.mobile,
      email: editFormData.email,
    };
    const newContents = [...contents];
    const index = contents.findIndex((content) => content.id === editContentId);
    newContents[index] = editedContent;
    setContents(newContents);
    setEditContentId(null);
    // setEditModal(false);
  };
  //Cencel button to same data
  const handleCancelClick = () => {
    setEditContentId(null);
  };

  // ==========search===========

  useEffect(() => {
    const result = contents?.filter((ele) => {
      return (
        ele?.order_id.toLowerCase().match(search.toLowerCase()) ||
        ele?.consignee_name.toLowerCase().match(search.toLowerCase()) ||
        ele?.name.toLowerCase().match(search.toLowerCase()) ||
        ele?.date.toLowerCase().match(search.toLowerCase()) ||
        ele?.mobile.toLowerCase().match(search.toLowerCase()) ||
        ele?.email.toLowerCase().match(search.toLowerCase()) ||
        ele?.total_amount.toLowerCase().match(search.toLowerCase())
      );
    });
    setfilterProductData(result);
  }, [search, contents]);
  // ==========end search===========

  // =================tab view===============
  const tabData = [
    {
      name: "All",
    },
    {
      name: "Done",
    },
    {
      name: "Progress",
    },
    {
      name: "Pending",
    },
    {
      name: "Cancell",
    },
    {
      name: "Cancell",
    },
  ];
  const [dateRangeVisable, setDateRangeVisable] = useState(0);

  const dataFilterFun = (i) => {
    setDateRangeVisable(i);
    if (i === 0) {
      setPageLoading(true);
      axios({
        method: "get",
        url: `https://tworingz.com/ecommerce/api/product/fetchAdminOrder.php`,
      })
        .then((res) => {
          if (res.data.statusCode === 0) {
            setContents([]);
            setfilterProductData([]);
            setPageLoading(false);
          } else {
            const userDataTable = res.data;
            setContents(userDataTable);
            setfilterProductData(userDataTable);
            setPageLoading(false);
          }
        })
        .catch((err) => {
          console.log("api error", err);
        });
    } else if (i === 1) {
      setPageLoading(true);
      axios({
        method: "get",
        url: `https://tworingz.com/ecommerce/api/product/fetchAdminOrderCompleted.php`,
      })
        .then((res) => {
          if (res.data.statusCode === 0) {
            setContents([]);
            setfilterProductData([]);
            setPageLoading(false);
          } else {
            const userDataTable = res.data;
            setContents(userDataTable);
            setfilterProductData(userDataTable);
            setPageLoading(false);
          }
        })
        .catch((err) => {
          console.log("api error", err);
        });
    } else if (i === 2) {
      setPageLoading(true);
      axios({
        method: "get",
        url: `https://tworingz.com/ecommerce/api/product/fetchAdminOrderProcessing.php`,
      })
        .then((res) => {
          if (res.data.statusCode === 0) {
            setContents([]);
            setfilterProductData([]);
            setPageLoading(false);
          } else {
            const userDataTable = res.data;
            setContents(userDataTable);
            setfilterProductData(userDataTable);
            setPageLoading(false);
          }
        })
        .catch((err) => {
          console.log("api error", err);
        });
    } else if (i === 3) {
      setPageLoading(true);
      axios({
        method: "get",
        url: `https://tworingz.com/ecommerce/api/product/fetchAdminOrderPending.php`,
      })
        .then((res) => {
          if (res.data.statusCode === 0) {
            setContents([]);
            setfilterProductData([]);
            setPageLoading(false);
          } else {
            const userDataTable = res.data;
            setContents(userDataTable);
            setfilterProductData(userDataTable);
            setPageLoading(false);
          }
        })
        .catch((err) => {
          console.log("api error", err);
        });
    } else {
      setPageLoading(true);
      axios({
        method: "get",
        url: `https://tworingz.com/ecommerce/api/product/fetchAdminOrderCancelled.php`,
      })
        .then((res) => {
          if (res.data.statusCode === 0) {
            setContents([]);
            setfilterProductData([]);
            setPageLoading(false);
          } else {
            const userDataTable = res.data;
            setContents(userDataTable);
            setfilterProductData(userDataTable);
            setPageLoading(false);
          }
        })
        .catch((err) => {
          console.log("api error", err);
        });
    }
  };

  // =================get leads count api============
  const [leadsCount, setLeads] = useState({
    totalLeads: 0,
    leadsPending: 0,
    leadsDone: 0,
    leadsProgress: 0,
    leadsCancel: 0,
  });

  console.log("leadsCount: ", leadsCount);

  const getLeadsCount = async () => {
    try {
      const response = await axios.get(
        `https://tworingz.com/ecommerce/api/product/fetchAdminOrder.php`
      );
      const orders = response.data;
      console.log("orders", orders);

      const counts = orders.reduce(
        (acc, order) => {
          acc.totalOrders++;
          switch (order.status) {
            case "Pending":
              acc.ordersPending++;
              break;
            case "Completed":
              acc.ordersCompleted++;
              break;
            case "Processing":
              acc.ordersProcessing++;
              break;
            case "Cancelled":
              acc.ordersCancelled++;
              break;
            default:
              break;
          }
          return acc;
        },
        {
          totalOrders: 0,
          ordersPending: 0,
          ordersCompleted: 0,
          ordersProcessing: 0,
          ordersCancelled: 0,
        }
      );

      setLeads(counts);
    } catch (err) {
      console.log("getLeadsCount api error", err);
    }
  };

  useEffect(() => {
    getLeadsCount();
  }, []);

  const getStatusBadgeColor = (status) => {
    switch (status) {
      case "Pending":
        return "#EF5350";
      case "Completed":
        return "#4CAF50";
      case "Processing":
        return "#FFB74D";
      default:
        return "#616161";
    }
  };

  return (
    <>
      <Modal className="modal fade" show={addCard} onHide={setAddCard}>
        <div className={`${screenWidth < 500 ? "p-3" : "update_container"}`}>
          <div className="update_inner_container">
            <form
              className="form-valide"
              action="#"
              method="post"
              onSubmit={handleProductUpdateApi}
            >
              <div className="row">
                <div className="col-xl-6">
                  <div className="form-group row">
                    <label
                      className="col-lg-4 col-form-label"
                      htmlFor="val-currency"
                    >
                      Select Item Category
                      <span className="text-danger"></span>
                    </label>
                    <div className="col-lg-6">
                      <select
                        defaultValue={"option"}
                        className="form-control form-control-lg"
                        onChange={handleCategorySelection}
                        name="selectitemcategory"
                      >
                        <option>Please select a category</option>
                        {allategory?.map((ele) => {
                          return (
                            <option key={ele?.id}>{ele.category_name}</option>
                          );
                        })}

                        {/* <option>Option 2</option>
                              <option>Option 3</option> */}
                      </select>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      className="col-lg-4 col-form-label"
                      htmlFor="val-username"
                    >
                      Item Name
                      <span className="text-danger">*</span>
                    </label>
                    <div className="col-lg-6">
                      <input
                        autoComplete="off"
                        type="text"
                        className="form-control"
                        id="val-username"
                        name="itemname"
                        value={productData.itemname}
                        placeholder="Enter Item Name"
                        onChange={handleChangeData}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      className="col-lg-4 col-form-label"
                      htmlFor="val-email"
                    >
                      MRP <span className="text-danger">*</span>
                    </label>
                    <div className="col-lg-6">
                      <input
                        autoComplete="off"
                        type="text"
                        className="form-control"
                        id="val-email"
                        name="mrp"
                        value={productData.mrp}
                        placeholder="Enter MRP"
                        onChange={handleChangeData}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      className="col-lg-4 col-form-label"
                      htmlFor="val-email"
                    >
                      Party Price <span className="text-danger">*</span>
                    </label>
                    <div className="col-lg-6">
                      <input
                        autoComplete="off"
                        type="text"
                        className="form-control"
                        id="val-email"
                        name="partyprice"
                        value={productData.partyprice}
                        placeholder="Enter Party Price"
                        onChange={handleChangeData}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      className="col-lg-4 col-form-label"
                      htmlFor="val-password"
                    >
                      Discount (%)
                      <span className="text-danger">*</span>
                    </label>
                    <div className="col-lg-6">
                      <input
                        autoComplete="off"
                        type="number"
                        className="form-control"
                        name="discount"
                        value={productData.discount}
                        placeholder="Enter Discount (%)"
                        onChange={handleChangeData}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      className="col-lg-4 col-form-label"
                      htmlFor="val-confirm-password"
                    >
                      Item Model
                      <span className="text-danger">*</span>
                    </label>
                    <div className="col-lg-6">
                      <input
                        autoComplete="off"
                        type="text"
                        className="form-control"
                        name="itemmodel"
                        value={productData.itemmodel}
                        placeholder="Enter Item Model"
                        onChange={handleChangeData}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-xl-6">
                  <div className="form-group row">
                    <label
                      className="col-lg-4 col-form-label"
                      htmlFor="val-confirm-password"
                    >
                      Minimum Quantity
                      <span className="text-danger">*</span>
                    </label>
                    <div className="col-lg-6">
                      <input
                        autoComplete="off"
                        type="number"
                        className="form-control"
                        name="minimumquantity"
                        value={productData.minimumquantity}
                        placeholder="Enter Minimum Quantity"
                        onChange={handleChangeData}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      className="col-lg-4 col-form-label"
                      htmlFor="val-confirm-password"
                    >
                      Stocks<span className="text-danger"></span>
                    </label>
                    <div className="col-lg-6">
                      <input
                        autoComplete="off"
                        type="number"
                        className="form-control"
                        name="stocks"
                        placeholder="Enter Stocks"
                        value={productData.stocks}
                        onChange={handleChangeData}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      className="col-lg-4 col-form-label"
                      htmlFor="val-confirm-password"
                    >
                      Item Image
                      <span className="text-danger">*</span>
                    </label>
                    <div className="col-lg-6">
                      <input
                        style={{ height: "36px" }}
                        autoComplete="off"
                        type="file"
                        className="form-control"
                        name="itemimage"
                        placeholder="Enter Model Number"
                        onChange={handleImage}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      className="col-lg-4 col-form-label"
                      htmlFor="val-confirm-password"
                    >
                      Description<span className="text-danger"></span>
                    </label>
                    <div className="col-lg-6">
                      <textarea
                        style={{
                          height: "200px",
                        }}
                        autoComplete="off"
                        type="number"
                        className="form-control"
                        name="description"
                        placeholder="Enter Description"
                        value={productData.description}
                        onChange={handleChangeData}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-8 ml-auto">
                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>
      <div className="order_container">
        <div className="col-12 order_inner_container">
          <div className="card orderInfoTableContainer">
            <div className="card-header leads_header">
              <h4 className="card-title">All Orders</h4>
              <div className="search_list">
                <i className="fa fa-search" />
                <input
                  type="text"
                  className="form-control"
                  autoComplete="off"
                  name="search"
                  // value=""
                  placeholder="Search Here...."
                  onChange={(e) => setSearch(e.target.value.trim())}
                />
              </div>
            </div>
            <div className="tabContainer">
              <Nav as="ul" className="nav-pills  light">
                {tabData.map(
                  (data, i) =>
                    i !== tabData?.length - 1 && (
                      <Nav.Item
                        as="li"
                        key={i}
                        onClick={() => dataFilterFun(i)}
                        className="nav-pills-item"
                        // style={{
                        //   backgroundColor: i === 0 ? "#e6defa" : "#fff",
                        // }}
                      >
                        <Nav.Link eventKey={data.name.toLowerCase()}>
                          {i === 1
                            ? `Done (${leadsCount?.ordersCompleted})`
                            : i === 2
                            ? `Progress (${leadsCount?.ordersProcessing})`
                            : i === 3
                            ? `Pending (${leadsCount?.ordersPending})`
                            : i === 4
                            ? `Cancel (${leadsCount?.ordersCancelled})`
                            : `All (${leadsCount?.totalOrders})`}
                        </Nav.Link>
                      </Nav.Item>
                    )
                )}
              </Nav>
            </div>
            {PageLoading ? (
              <ReactLoading
                type="spinningBubbles"
                color="#40189d"
                height={"5%"}
                width={"5%"}
                className="loadingStyle"
              />
            ) : (
              <div className="card-body">
                <div className="w-100 table-responsive">
                  <div id="example_wrapper" className="dataTables_wrapper">
                    <form onSubmit={handleEditFormSubmit}>
                      <table
                        id="example"
                        className="display w-100 dataTable my_data_table table table-striped"
                      >
                        <thead>
                          <tr>
                            <th>Order Id</th>
                            <th>Order Date</th>
                            <th>Customer Name</th>
                            <th>Address</th>
                            {/* <th>Model</th> */}
                            <th>Email</th>
                            <th>Mobile No</th>
                            <th>Order Amount</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody className="diynamic_color">
                          {filterProductData?.length < 0 ? (
                            <h5
                              style={{
                                position: "absolute",
                                top: "50%",
                                left: "45%",
                              }}
                            >
                              No Data Found
                            </h5>
                          ) : filterProductData?.length > 0 ? (
                            filterProductData?.map((content, index) => (
                              <>
                                {editContentId === content?.id ? (
                                  <Editable
                                    editFormData={editFormData}
                                    handleEditFormChange={handleEditFormChange}
                                    handleCancelClick={handleCancelClick}
                                  />
                                ) : (
                                  <tr>
                                    <td>{content?.order_id}</td>
                                    <td>{content?.date}</td>
                                    <td>{content?.name}</td>
                                    <td>{content?.consignee_address}</td>
                                    <td>{content?.email}</td>
                                    <td>{content?.mobile}</td>
                                    <td>{content?.total_amount}</td>
                                    {/* <td>{content?.status}</td> */}
                                    <td>
                                      <span
                                        style={{
                                          backgroundColor: `${getStatusBadgeColor(
                                            content?.status
                                          )}`,
                                          padding: "2px 8px",
                                          color: "white",
                                          borderRadius: "25px",
                                          fontSize: "12px",
                                        }}
                                      >
                                        {content?.status}
                                      </span>
                                    </td>
                                    {/* <td>
                                      {content?.hot_product === false
                                        ? "active"
                                        : "In Active"}
                                    </td> */}
                                    <td>
                                      <div className="d-flex">
                                        {/* <Link
                                          className="btn btn-danger shadow btn-xs sharp mr-2"
                                          onClick={() =>
                                            handleProductDelete(content?.id)
                                          }
                                        >
                                          <i className="fa fa-trash"></i>
                                        </Link> */}
                                        <Link
                                          className="btn btn-primary	 shadow btn-xs sharp mr-2"
                                          // onClick={(event) =>
                                          //   handleEditClick(event, content)
                                          // }
                                          // onClick={(even) =>
                                          //   handleProductUpdate(even, content)
                                          // }
                                          to={`/download/${content?.order_id}`}
                                        >
                                          <i className="fa fa-eye"></i>
                                        </Link>
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </>
                            ))
                          ) : (
                            <h5
                              style={{
                                position: "absolute",
                                top: "50%",
                                left: "45%",
                              }}
                            >
                              No Data Found
                            </h5>
                          )}
                        </tbody>
                      </table>
                    </form>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default DownloadReport;
