import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Modal, Nav } from "react-bootstrap";
import swal from "sweetalert";
import axios from "axios";
import Editable from "../components/Editable";
import "../css/orderinfo.css";
import ReactLoading from "react-loading";
import { RangeDatePicker } from "@y0c/react-datepicker";
import "@y0c/react-datepicker/assets/styles/calendar.scss";
import { Button } from "react-bootstrap";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { productUpdateApi, productDeleteApi } from "../lib/api";
// ==============get user Info=============

const OrderDescription = () => {
  const { order_id } = useParams();
  const navigate = useNavigate();
  const apiUrl = useSelector((state) => state.login.apiUrl);
  const [contents, setContents] = useState([]);
  const [PageLoading, setPageLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [filterProductData, setfilterProductData] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [selectedCategoryName, setSelectedCategoryName] = useState("Pending");
  const [allOrdes, setAllOrdes] = useState([]);
  const [orderCurrentStatus, setOrderCurrentStatus] = useState([]);
  // ================Get Product===========

  const getProductData = async () => {
    setPageLoading(true);
    await axios({
      method: "post",
      url: `https://tworingz.com/ecommerce/api/product/fetchProductByOrderId.php`,
      data: {
        order_id: order_id,
      },
    })
      .then((res) => {
        if (res.data.statusCode === 0) {
          setContents([]);
          setfilterProductData([]);
          setPageLoading(false);
        } else {
          const userDataTable = res.data.data;
          setOrderCurrentStatus(res.data.status);
          setSelectedCategoryName(res.data.status);
          setContents(userDataTable);
          setfilterProductData(userDataTable);
          setPageLoading(false);
        }
      })
      .catch((err) => {
        setPageLoading(false);
        console.log("api error", err);
      });
  };

  useEffect(() => {
    getProductData();
  }, []);

  const getAllOrder = async () => {
    setPageLoading(true);
    await axios({
      method: "get",
      url: `https://tworingz.com/ecommerce/api/product/fetchAdminOrder.php`,
    })
      .then((res) => {
        if (res.data.statusCode === 0) {
          setAllOrdes([]);
        } else {
          const userDataTable = res.data;
          setAllOrdes(userDataTable);
        }
      })
      .catch((err) => {
        console.log("api error", err);
      });
  };

  useEffect(() => {
    getAllOrder();
  }, []);
  // ====================Delete Product========

  //Edit start

  const [editContentId, setEditContentId] = useState(null);

  const [editFormData, setEditFormData] = useState({
    name: "",
    department: "",
    gender: "",
    education: "",
    mobile: "",
    email: "",
  });

  //update data function
  const handleEditFormChange = (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;
    const newFormData = { ...editFormData };
    newFormData[fieldName] = fieldValue;
    setEditFormData(newFormData);
  };

  // edit form data submit
  const handleEditFormSubmit = (event) => {
    event.preventDefault();
    const editedContent = {
      id: editContentId,
      name: editFormData.name,
      department: editFormData.department,
      gender: editFormData.gender,
      education: editFormData.education,
      mobile: editFormData.mobile,
      email: editFormData.email,
    };
    const newContents = [...contents];
    const index = contents.findIndex((content) => content.id === editContentId);
    newContents[index] = editedContent;
    setContents(newContents);
    setEditContentId(null);
    // setEditModal(false);
  };
  //Cencel button to same data
  const handleCancelClick = () => {
    setEditContentId(null);
  };

  const getStatusBadgeColor = (status) => {
    switch (status) {
      case "Pending":
        return "#EF5350";
      case "Completed":
        return "#4CAF50";
      case "Processing":
        return "#FFB74D";
      default:
        return "#616161";
    }
  };

  const handleCategorySelection = async (event) => {
    const selectedCategoryName = event.target.value;
    setSelectedCategoryName(selectedCategoryName);
    setPageLoading(true);
    await axios({
      method: "post",
      url: `https://tworingz.com/ecommerce/api/product/updateOrderStatus.php`,
      data: {
        status: selectedCategoryName,
        order_id: order_id,
      },
    })
      .then((res) => {
        if (res.data.statusCode === 0) {
          setContents([]);
          setfilterProductData([]);
          setPageLoading(false);
        } else {
          setPageLoading(false);
          const userDataTable = res.data;
          navigate("/download");
        }
      })
      .catch((err) => {
        setPageLoading(false);
        console.log("api error", err);
      });
  };

  console.log("filterProductData: ", filterProductData);

  return (
    <>
      <div className="order_container">
        <div className="col-12 order_inner_container">
          <div className="card orderInfoTableContainer">
            <div className="card-header leads_header">
              <h4 className="card-title">All Products</h4>

              <div className="search_list">
                <i className="fa fa-search" />
                <input
                  type="text"
                  className="form-control"
                  autoComplete="off"
                  name="search"
                  // value=""
                  placeholder="Search Here...."
                  onChange={(e) => setSearch(e.target.value.trim())}
                />
              </div>
            </div>
            {PageLoading ? (
              <ReactLoading
                type="spinningBubbles"
                color="#40189d"
                height={"5%"}
                width={"5%"}
                className="loadingStyle"
              />
            ) : (
              <div className="card-body">
                <div className="w-100 table-responsive">
                  <div id="example_wrapper" className="dataTables_wrapper">
                    <form onSubmit={handleEditFormSubmit}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-end",
                          gap: "50px",
                        }}
                      >
                        <label
                          style={{
                            fontWeight: 800,
                            fontSize: "22px",
                          }}
                        >
                          Order status :{" "}
                        </label>
                        <select
                          style={{
                            width: "130px",
                            textAlign: "center",
                            backgroundColor: `${getStatusBadgeColor(
                              selectedCategoryName
                            )}`,
                            color: "#FFF",
                            padding: "0px !importent",
                          }}
                          defaultValue={orderCurrentStatus}
                          className="form-control form-control-lg"
                          onChange={handleCategorySelection}
                          name="selectitemcategory"
                        >
                          <option
                            style={{
                              backgroundColor: "#EF5350",
                            }}
                          >
                            Pending
                          </option>
                          <option
                            style={{
                              backgroundColor: "#FFB74D",
                            }}
                          >
                            Processing
                          </option>
                          <option
                            style={{
                              backgroundColor: "#4CAF50",
                            }}
                          >
                            Completed
                          </option>
                          <option
                            style={{
                              backgroundColor: "#616161",
                            }}
                          >
                            Cancelled
                          </option>
                        </select>
                      </div>
                      <table
                        id="example"
                        className="display w-100 dataTable my_data_table table table-striped"
                      >
                        <thead>
                          <tr>
                            <th>Product Name</th>
                            <th>Image</th>
                            <th>Name</th>
                            <th>Mobile No</th>
                            <th>Product Model</th>
                            <th>MRP</th>
                            <th>Quantity</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody className="diynamic_color">
                          {filterProductData?.length < 0 ? (
                            <h5
                              style={{
                                position: "absolute",
                                top: "50%",
                                left: "45%",
                              }}
                            >
                              No Data Found
                            </h5>
                          ) : filterProductData.length > 0 ? (
                            filterProductData?.map((content, index) => (
                              <>
                                {editContentId === content?.id ? (
                                  <Editable
                                    editFormData={editFormData}
                                    handleEditFormChange={handleEditFormChange}
                                    handleCancelClick={handleCancelClick}
                                  />
                                ) : (
                                  <tr>
                                    <td>{content?.name}</td>
                                    <td>
                                      <img
                                        style={{
                                          width: "100px",
                                          height: "100px",
                                        }}
                                        src={content?.image_link}
                                      />
                                    </td>
                                    <td>{content?.consignee_name}</td>
                                    <td>{content?.consignee_mobile}</td>
                                    <td>{content?.model}</td>
                                    <td>{content?.price}</td>
                                    <td>{content?.qty}</td>
                                    <td>
                                      <span
                                        style={{
                                          backgroundColor: `${getStatusBadgeColor(
                                            content?.status
                                          )}`,
                                          padding: "2px 8px",
                                          color: "white",
                                          borderRadius: "25px",
                                          fontSize: "12px",
                                        }}
                                      >
                                        {content?.status}
                                      </span>
                                    </td>
                                  </tr>
                                )}
                              </>
                            ))
                          ) : (
                            <h5
                              style={{
                                position: "absolute",
                                top: "50%",
                                left: "45%",
                              }}
                            >
                              No Data Found
                            </h5>
                          )}
                        </tbody>
                      </table>
                    </form>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default OrderDescription;
